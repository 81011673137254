

















import { Component, Vue, Ref } from "vue-property-decorator"
import HelloWorld from "./components/HelloWorld.vue"
import NavDrawer, { MenuItem } from "@/components/NavDrawer.vue"
import AppBar from "@/components/AppBar.vue"

@Component({
  components: {
    HelloWorld,
    NavDrawer,
    AppBar,
  },
})
export default class App extends Vue {
  private sidebarMenu = true
  private title = ""
}
