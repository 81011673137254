






































import { Component, Vue, Prop } from "vue-property-decorator"

export interface MenuItem {
  icon: string
  label: string
  to: string
}

@Component({
  components: {},
})
export default class Drawer extends Vue {
  @Prop({ default: true }) sidebarMenu!: boolean
  @Prop({ default: false }) toggleMini!: boolean

  private items: Array<MenuItem> = [
    {
      icon: "mdi-view-dashboard-outline",
      label: "Dashboard",
      to: "/",
    },
    {
      icon: "mdi-plus",
      label: "New Request",
      to: "/new-request",
    },
    {
      icon: "mdi-folder-multiple-outline",
      label: "Cases",
      to: "/case",
    },
    {
      icon: "mdi-compare-horizontal",
      label: "Transaction",
      to: "transaction",
    },
    {
      icon: "mdi-home-outline",
      label: "Rental",
      to: "rental",
    },
    {
      icon: "mdi-chart-multiple",
      label: "Analytics",
      to: "analytics",
    },
    {
      icon: "mdi-account-outline",
      label: "User Account",
      to: "account",
    },
  ]

  get mini() {
    return this.$vuetify.breakpoint.smAndDown || this.toggleMini
  }
}
