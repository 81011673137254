












import { Component, Vue, Prop } from "vue-property-decorator"

@Component({
  components: {},
})
export default class AppBar extends Vue {
  @Prop() readonly title!: string
}
