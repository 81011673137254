import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { AxiosPlugin } from './plugins/Axios'
import Vuelidate from 'vuelidate'
import Moment from 'vue-moment'

Vue.config.productionTip = false
Vue.use(AxiosPlugin)
Vue.use(Vuelidate)
Vue.use(Moment)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
