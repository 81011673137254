import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import Dashboard from "../views/dashboard/Dashboard.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/case",
    name: "CaseListView",
    component: () => import("@/views/case/CaseListView.vue"),
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("@/views/account/Account.vue"),
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: () => import("@/views/analytics/Analytics.vue"),
  },
  {
    path: "/new-request",
    name: "NewRequest",
    component: () => import("@/views/newRequest/NewRequest.vue"),
  },
  {
    path: "/transaction",
    name: "Transaction",
    component: () => import("@/views/transaction/Transaction.vue"),
  },
  {
    path: "/rental",
    name: "Rental",
    component: () => import("@/views/rental/Rental.vue"),
  },
  {
    path: "/case/detail/:id",
    name: "CaseDetail",
    component: () => import("@/views/case/CaseDetailView.vue"),
    props: true,
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
})

export default router
