













import { Component, Vue } from "vue-property-decorator"

@Component({
  components: {},
})
export default class Dashboard extends Vue {
  private text = "test"
  private show = false
  // private year = parseInt(this.$moment().format('YYYY'))

  onClick() {
    this.show = !this.show
  }
}
