import Axios, { AxiosStatic } from "axios"
import { Vue } from "vue-property-decorator"
// import AppConst from '@/utils/AppConst'

export function AxiosPlugin(vue: typeof Vue, options?: any): void {
  Axios.defaults.baseURL = process.env.VUE_APP_API_URL
  // if (vue.$cookies.isKey(AppConst.ACCESS_TOKEN)) {
  //     Axios.defaults.headers.common.Authorization = vue.$cookies.get(AppConst.ACCESS_TOKEN)
  // }

  // Axios.interceptors.request.use(req => {
  //     return req
  // })

  // Axios.interceptors.response.use(
  //     resp => {
  //         return resp
  //     },
  //     err => {
  //         let resp = err.response
  //         if (
  //             resp &&
  //             resp.status === 401 &&
  //             resp.data &&
  //             resp.data.code &&
  //             (resp.data.code === 4011 || resp.data.code === 4012)
  //         ) {
  //             vue.$cookies.remove(AppConst.ACCESS_TOKEN)
  //             vue.$router.push('/login')
  //         }

  //         return Promise.reject(err)
  //     }
  // )

  vue.$axios = Axios
}

declare module "vue/types/vue" {
  interface Vue {
    $axios: AxiosStatic
  }

  interface VueConstructor {
    $axios: AxiosStatic
  }
}
